<template>
  <section class="card-preview-chat media-preview">
    <div ref="cardPreview" class="content-slider-chat-log">
      <div
        v-if="getCards.length > 1"
        v-bind:style="{ height: getHeightArr }"
        @click="back()"
        class="arrows-msg"
      >
        <span class="arrow-back material-icons">arrow_back_ios</span>
      </div>
      <div class="slider">
        <div
          v-bind:style="{ height: getHeight(), width: this.cardWith + 'px' }"
          class="slide_viewer"
        >
          <div
            class="slide"
            v-for="(card, index) in getCards"
            :key="index"
            v-bind:style="{
              transform: getTransform(position),
              opacity: getOpacity(index)
            }"
          >
            <vs-card class="cardx mb-0">
              <div v-if="card.header && card.header.url" slot="media">
                <img :src="card.header.url" />
              </div>
              <div class="title text-black">{{ card.title }}</div>
              <div class="subtitle text-black">{{ card.subtitle }}</div>
              <div class="buttons">
                <div
                  v-for="(button, index) in card.options"
                  v-if="button.text"
                  :key="index"
                  class="option"
                >
                  <span>{{ button.text }}</span>
                </div>
              </div>
            </vs-card>
          </div>
        </div>
      </div>
      <div
        v-if="getCards.length > 1"
        v-bind:style="{ height: getHeightArr }"
        @click="forward()"
        class="arrows-msg"
      >
        <span class="arrow-forward material-icons">arrow_forward_ios</span>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PreviewCard',
  props: {
    msg: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      position: 0,
      cardWith: 300,
      showPreviewVideo: false,
      output: ''
    }
  },
  computed: {
    getCards() {
      return this.msg.responseCard
    },
    getHeightArr() {
      return '250px'
      //   if (this.$refs.cardPreview) return this.$refs.cardPreview.clientHeight
      //   else return 0
    },
    cardSize() {
      return this.msg.responseCard.length
    }
  },
  methods: {
    back() {
      this.sliderChange({ forward: false })
    },
    forward() {
      this.sliderChange({ forward: true })
    },
    getOptions() {
      const card = this.getCard()
      return card.options
    },
    getHeight() {
      return 'fit-content'
    },
    getTransform(index) {
      return 'translateX(-' + this.cardWith * index + 'px)'
    },
    getOpacity(index) {
      if (index == this.position) return 1
      else return 0
    },
    getCard(index) {
      return this.msg.responseCard[index]
    },
    getTitle(index) {
      const card = this.getCard(index)
      return card.title
    },
    getSubtitle(index) {
      const card = this.getCard(index)
      return card.subtitle
    },
    getImage(index) {
      const card = this.getCard(index)
      return card.header.url
    },
    lastToFirst(array) {
      return array.unshift(array.pop())
    },
    sliderChange(newVal) {
      if (newVal.forward) {
        if (this.position + 1 < this.cardSize) {
          this.position++
        } else {
          this.position = 0
        }
      } else {
        if (this.position == 0) {
          this.position = this.cardSize - 1
        } else {
          this.position--
        }
      }
    }
  },
  watch: {
    file() {
      this.preview(this.file)
    },
    savedFile() {
      this.preview()
    }
  }
}
</script>

<style lang="scss">
.content-slider-chat-log {
  display: flex;
  justify-content: space-between;
  .arrows-msg {
    cursor: pointer;
    display: flex;
    span {
      margin: auto;
    }
  }
}
.card-preview-chat {
  width: 100%;
  position: relative;
  overflow: visible;
  /* Slider */

  .slider {
    width: 100%;
    position: relative;
    margin: 0 auto;
  }
  .slide_viewer {
    width: 100%;
    overflow: hidden;
    display: flex;
    position: relative;
  }
  .slide_group {
    height: 100%;
    position: relative;
    width: 100%;
  }
  .slide {
    transition: 0.5s ease-in-out;
    height: 100%;
    width: 250px;
    min-width: 100%;
    display: block;
  }

  .con-vs-card {
    background-color: #f0f0f0;
  }
  img {
    height: auto;
    width: 100%;
  }
  .title {
    width: 100%;
    font-weight: bold;
    font-size: 1rem;
  }
  .subtitle {
    font-size: 1rem;
  }
  .buttons {
    display: flex;
    flex-direction: column;
    padding: 15px;
    border-radius: 0 0 20px 20px;

    .option {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      border-radius: 5px;
      line-height: 1rem;
      background-color: white;
      color: black;

      &:not(:last-of-type) {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
